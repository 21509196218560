<template>
  <svg
    width="20"
    height="24"
    viewBox="0 0 20 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M18.7245 5.03543L16.1734 6.51105L10.0042 10.0709L3.83493 6.51105H3.82659L1.27553 5.03543L0 5.77741V7.24468V17.3239L2.55106 18.7995V8.7203L7.44477 11.5465L8.72864 12.2885L10.0042 13.0221L11.2797 12.2885L12.5552 11.5465L20 7.24468V5.77741L18.7245 5.03543Z"
      fill="white"
    />
    <path
      d="M10.0042 2.95123L14.8979 5.77741L17.4489 4.30179L10.0042 0L2.55107 4.30179L5.11047 5.77741L10.0042 2.95123Z"
      fill="white"
    />
    <path
      d="M17.4489 10.1792V18.7995L20 17.3239V8.71196L17.4489 10.1792Z"
      fill="white"
    />
    <path
      d="M8.72863 19.4081L3.83493 16.5819H3.82659L3.81825 19.5248L8.72863 22.3593L10.0042 23.0929L11.2797 22.3593L16.1817 19.5248L16.1734 16.5819L11.2797 19.4081V13.7557L10.0042 14.4977L8.72863 13.7641V19.4164"
      fill="white"
    />
  </svg>
</template>
