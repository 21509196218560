<template>
  <Card class="summary">
    <template #header>
      <div
        class="flex flex-row justify-content-between align-items-center summary-title"
      >
        <h3>Sumario de Pagos</h3>
        <IconPagandoWhite />
      </div>
      <div class="flex flex-row summary-resumen">
        <div class="grid m-0 p-0 my-2 w-full">
          <div class="col-6">TOTAL A PAGAR</div>
          <div class="col-6">
            <div class="flex justify-content-end text-xl">{{ Number(currentPlayData.ticket.total).toFixed(2) }} BUSD</div>
            <div class="flex justify-content-end text-lg mt-1">{{ convert(currentPlayData.ticket.total).toFixed(2) }}  Bs</div>
          </div>
        </div>
      </div>
    </template>
    <template #content>
      <div class="w-full">
        <div v-for="item in payOptionsNotEmpty" :key="item.code" class="w-full">
          <span class="summary_detail_title">{{ item.title }}</span>
          <div
            class="flex flex-row w-full mt-1"
            v-for="moneda in monedaOptions"
            :key="moneda.code"
          >
            <div
              v-if="
                currentPlayData.pays.filter(
                  (o) =>
                    o.currency_id === moneda.code &&
                    item.monedaOptionByCodes.includes(moneda.code) &&
                    item.options.some((option) => option.id === o.fk_payment_method_id && option.fk_currency === o.currency_id)
                ).length
              "
              class="flex flex-row justify-content-between w-full"
            >
              <span class="mt-3">{{ moneda.text }}</span>
              <span class="mt-3 ml-auto mr-4"
                >({{
                  Number(
                    currentPlayData.pays
                      .filter((o) =>
                        o.currency_id === moneda.code &&
                        item.monedaOptionByCodes.includes(moneda.code) &&
                        item.options.some((option) => option.id === o.fk_payment_method_id && option.fk_currency === o.currency_id)
                      )
                      .reduce((a, b) => a + Number(b.amount), 0)
                  ).toFixed(2)
                }}
                {{ moneda.value }})</span
              >
              <span class="mt-3"
                >{{
                  Number(
                    currentPlayData.pays
                      .filter((o) =>
                        o.currency_id === moneda.code && item.monedaOptionByCodes.includes(moneda.code) &&
                        item.options.some((option) => option.id === o.fk_payment_method_id && option.fk_currency === o.currency_id)
                      )
                      .reduce(
                        (a, b) => a + Number(b.amount / moneda.exchange_rate),
                        0
                      )
                  ).toFixed(2)
                }}
                BUSD</span
              >
            </div>
          </div>

          <div
            class="flex flex-row justify-content-between w-full mt-1 mb-1 border-top-1 border-bottom-1"
          >
            <span class="mt-3"><b>Sub-Total</b></span
            ><span class="mt-3"
              ><b
                >{{
                  Number(
                    currentPlayData.pays
                      .filter((o) =>
                        item.monedaOptionByCodes.includes(o.currency_id) &&
                        item.options.some((option) => option.id === o.fk_payment_method_id && option.fk_currency === o.currency_id)
                      )
                      .reduce(
                        (a, b) =>
                          a +
                          Number(
                            b.amount /
                              this.monedaOptions.find(
                                (e) => e.code === b.currency_id
                              ).exchange_rate
                          ),
                        0
                      )
                  ).toFixed(2)
                }}
                BUSD</b
              ></span
            >
          </div>
        </div>
        <div
          class="flex flex-row justify-content-between w-full mt-1"
          style="font-size: 13px"
        >
          <span class="mt-3"><b>TOTAL ABONADO</b></span
          ><span class="mt-3"
            ><b
              >{{
                Number(
                  currentPlayData.pays.reduce(
                    (a, b) =>
                      a +
                      Number(
                        b.amount /
                          this.monedaOptions.find(
                            (e) => e.code === b.currency_id
                          ).exchange_rate
                      ),
                    0
                  )
                ).toFixed(2)
              }}
              BUSD</b
            ></span
          >
        </div>
      </div>
    </template>
    <template #footer>
      <div class="flex flex-row summary-resumen">
        <div class="grid m-0 p-0 my-2 w-full">
          <div class="col-6">RESTA</div>
          <div class="col-6">
            <div class="flex justify-content-end text-xl">{{ Number(currentPlayData.ticket.debt).toFixed(2) }} BUSD</div>
            <div class="flex justify-content-end text-lg mt-1">{{ convert(currentPlayData.ticket.debt).toFixed(2) }} Bs</div>
          </div>
        </div>
      </div>
    </template>
  </Card>
</template>

<script>
import { mapState, mapGetters } from "vuex";
import IconPagandoWhite from "@/components/svg/icons/IconPagandoWhite.vue"
export default {
  components: {
    IconPagandoWhite
  },
  methods: {
    convert(amount) {
      let moneda = this.monedaOptions.find((e) => e.code === 1)
      return !isNaN(amount) && moneda &&
        !isNaN(moneda.exchange_rate)
        ? Number(amount) *
            Number(moneda.exchange_rate)
        : Number(0);
    }
  },
  computed: {
    ...mapGetters("ticketoffice", ["activeClient", "currentPlayData"]),
    ...mapState("ticketoffice", [
      "is_processing_pay",
      "payOptions",
      "monedaOptions",
    ]),
    payOptionsNotEmpty() {
      const payOptions = [];
      this.payOptions.forEach((payOption) => {
        this.monedaOptions.forEach((moneda) => {
          const paysMonedaOptionByCodes = this.currentPlayData.pays.filter(
            (o) =>
              o.currency_id === moneda.code &&
              payOption.monedaOptionByCodes.includes(moneda.code) &&
              payOption.options.some((option) => option.id === o.fk_payment_method_id && option.fk_currency === o.currency_id)
          ).length;
          
          if (paysMonedaOptionByCodes > 0) {
            if (!payOptions.some((option) => option.title === payOption.title)) {
              payOptions.push(payOption);
            }
          }
        });
      });

      return payOptions;
    },
  },
};
</script>

<style lang="scss" scoped>
.summary {
  min-height: 100%;
  background: #F1F1F1;
  border: 1px solid rgba(0, 0, 0, 0.25);
  box-shadow: 0px 4px 4px rgb(0 0 0 / 25%);
  border-radius: 6px;
  display: flex;
  flex-direction: column;
}
.summary-title {
  background: #404040;
  border-radius: 6px 6px 0px 0px;
  height: 50px;
  font-family: "Roboto";
  color: #fff;
  padding: 8px;
  h3 {
    margin: auto 0 auto 0;
    font-weight: 500;
    line-height: 1.4;
  }
}
.summary-resumen {
  background: #cc195a;
  height: 55px;
  font-family: "Roboto";
  color: #fff;
  font-weight: 600;
  height: 70px;
}
.p-card-footer {
  .summary-resumen {
    background: #404040;
    border-radius: 0px 0px 6px 6px;
  }
}

.summary_detail_title {
  font-family: Roboto;
  font-size: 1rem;
  font-weight: 600;
  line-height: 12px;
  letter-spacing: 0em;
  text-align: center;
}
</style>
